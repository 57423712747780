import React, { useEffect, useState } from 'react';

import '../static/css/main.css'
import '../static/css/normalize.css'
import { useNavigate } from 'react-router-dom';

import logo1 from '../static/img/menuLogo.svg';
// import logo2 from '../static/img/logo-2.svg';
import logo2 from '../static/img/logo2-1.svg';
import burger1 from '../static/img/burger-1.svg';
import burger2 from '../static/img/burger-2.svg';
import s1Image1 from '../static/img/s1-image-1.png';
import s1Image1Mob from '../static/img/s1-image-1-mob.png';
import s2Image1 from '../static/img/Rectangle 168.png';
import s2Image2 from '../static/img/Rectangle 169.png';
import s2Image3 from '../static/img/Rectangle 170.png';
import x_img1 from "../static/img/''-1.jpg"
import x_img2 from "../static/img/''-2.jpg"
import s3img2 from "../static/img/s3-image-2.png"
import s4Icon1 from '../static/img/s4-icon-1.svg';
import s4Icon2 from '../static/img/s4-icon-2.svg';
import s3Image2 from '../static/img/s3-image-2.png';
import s5Image1 from '../static/img/s5-image-1.jpg';
import s5Image2 from '../static/img/s5-image-2.jpg';

import s6Icon1 from '../static/img/s4-icon-1.svg';
import s6Icon2 from '../static/img/s4-icon-2.svg';
import s7Icon1 from '../static/img/s4-icon-1.svg';
import s7Icon2 from '../static/img/s4-icon-2.svg';

import footerLogo from '../static/img/logo-1.svg';
import popupIcon from '../static/img/burger-2.png';
import ContactUs from './ContactUs';
import PrivacyPolicyModal from './PrivacyPolicyModal';
import { testStripeHook } from './Api/course';


function LandingPage({ business }) {
    const navigate = useNavigate();

    const [showFormContactUs, setShowFormContactUs] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isExpanded1, setIsExpanded1] = useState(false);
    const [isExpanded2, setIsExpanded2] = useState(false);
    const [isExpanded3, setIsExpanded3] = useState(false);
    const [isExpanded89, setIsExpanded89] = useState(false);
    const [isExpanded67, setIsExpanded67] = useState(false);
    const [isExpanded45, setIsExpanded45] = useState(false);

    const handleClickLogIn = () => {
        navigate('/login');
    }

    const toggleVisibilityNew3 = () => {
        let element = document.querySelector('.section-7-tab-body-4');
        let element2 = document.querySelector('.tab-title-img-402');
        let element3 = document.querySelector('.tab-title-img-401');
        let element4 = document.querySelector('.section-7-tab-title-4');

        if (isExpanded3) {
            // Закрыть
            element.style.display = 'none';
            element2.style.display = 'flex';
            element3.style.display = 'none';
            element4.style.borderBottomLeftRadius = "10px";
            element4.style.borderBottomRightRadius = "10px";
        } else {
            // Открыть
            element.style.display = 'flex';
            element2.style.display = 'none';
            element3.style.display = 'flex';
            element4.style.borderBottomLeftRadius = "0px";
            element4.style.borderBottomRightRadius = "0px";
        }

        // Переключаем состояние
        setIsExpanded3(!isExpanded3);
    };



    const toggleVisibilityNew2 = () => {
        let element = document.querySelector('.section-7-tab-body-3');
        let element2 = document.querySelector('.tab-title-img-302');
        let element3 = document.querySelector('.tab-title-img-301');
        let element4 = document.querySelector('.section-7-tab-title-3');

        if (isExpanded2) {
            // Закрыть
            element.style.display = 'none';
            element2.style.display = 'flex';
            element3.style.display = 'none';
            element4.style.borderBottomLeftRadius = "10px";
            element4.style.borderBottomRightRadius = "10px";
        } else {
            // Открыть
            element.style.display = 'flex';
            element2.style.display = 'none';
            element3.style.display = 'flex';
            element4.style.borderBottomLeftRadius = "0px";
            element4.style.borderBottomRightRadius = "0px";
        }

        // Переключаем состояние
        setIsExpanded2(!isExpanded2);
    };

    const toggleVisibilityNew1 = () => {
        let element = document.querySelector('.section-7-tab-body-2');
        let element2 = document.querySelector('.tab-title-img-202');
        let element3 = document.querySelector('.tab-title-img-201');
        let element4 = document.querySelector('.section-7-tab-title-2');

        if (isExpanded1) {
            // Закрыть
            element.style.display = 'none';
            element2.style.display = 'flex';
            element3.style.display = 'none';
            element4.style.borderBottomLeftRadius = "10px";
            element4.style.borderBottomRightRadius = "10px";
        } else {
            // Открыть
            element.style.display = 'flex';
            element2.style.display = 'none';
            element3.style.display = 'flex';
            element4.style.borderBottomLeftRadius = "0px";
            element4.style.borderBottomRightRadius = "0px";
        }

        // Переключаем состояние
        setIsExpanded1(!isExpanded1);
    };

    function toggleVisibilityNew() {
        console.log('toggleVisibilityNew')
        let element = document.querySelector('.section-7-tab-body-1');
        let element2 = document.querySelector('.tab-title-img-102');
        let element3 = document.querySelector('.tab-title-img-101');
        let element4 = document.querySelector('.section-7-tab-title-1');

        if (isExpanded) {
            // Закрыть
            element.style.display = 'none';
            element2.style.display = 'flex';
            element3.style.display = 'none';
            element4.style.borderBottomLeftRadius = "10px";
            element4.style.borderBottomRightRadius = "10px";
        } else {
            // Открыть
            element.style.display = 'flex';
            element2.style.display = 'none';
            element3.style.display = 'flex';
            element4.style.borderBottomLeftRadius = "0px";
            element4.style.borderBottomRightRadius = "0px";
        }

        // Переключаем состояние
        setIsExpanded(!isExpanded);
    }

    function toggleVisibilityNew0() {
        console.log('toggleVisibilityNew0')
        let element = document.querySelector('.section-7-tab-body-0');
        let element2 = document.querySelector('.tab-title-img-002');
        let element33 = document.querySelector('.tab-title-img-0011');
        let element4 = document.querySelector('.section-7-tab-title-0');

        if (isExpanded) {
            // Закрыть
            element.style.display = 'none';
            element2.style.display = 'flex';
            element33.style.display = 'none';
            element4.style.borderBottomLeftRadius = "10px";
            element4.style.borderBottomRightRadius = "10px";
        } else {
            // Открыть
            element.style.display = 'flex';
            element2.style.display = 'none';
            element33.style.display = 'flex';
            element4.style.borderBottomLeftRadius = "0px";
            element4.style.borderBottomRightRadius = "0px";
        }

        // Переключаем состояние
        setIsExpanded(!isExpanded);
    }

    function toggleVisibility3() {
        console.log('toggleVisibility3')
        let element = document.getElementById('mobile-conteiner');
        let element1 = document.getElementById('burger-2');
        let element2 = document.getElementById('burger-1');
        if (element.style.display === 'flex') {
            element.style.display = 'none';
        } else {
            element.style.display = 'flex';
        };
        if (element1.style.display === 'flex') {
            element1.style.display = 'none';
        } else {
            element1.style.display = 'flex';
        };
        if (element2.style.display === 'none') {
            element2.style.display = 'flex';
        } else {
            element2.style.display = 'none';
        }
    };

    function toggleVisibility() {
        console.log('toggleVisibility')
        let element = document.getElementById('mobile-conteiner');
        let element1 = document.getElementById('burger-2');
        let element2 = document.getElementById('burger-1');
        if (element.style.display === 'none') {
            element.style.display = 'flex';
        } else {
            element.style.display = 'none';
        };
        if (element1.style.display === 'none') {
            element1.style.display = 'flex';
        } else {
            element1.style.display = 'none';
        };
        if (element2.style.display === 'flex') {
            element2.style.display = 'none';
        } else {
            element2.style.display = 'flex';
        }
    };
    function toggleVisibility2() {
        let element = document.getElementById('mobile-conteiner');
        let element1 = document.getElementById('burger-2');
        let element2 = document.getElementById('burger-1');
        if (element.style.display === 'flex') {
            element.style.display = 'none';
        } else {
            element.style.display = 'flex';
        };
        if (element1.style.display === 'flex') {
            element1.style.display = 'none';
        } else {
            element1.style.display = 'flex';
        };
        if (element2.style.display === 'none') {
            element2.style.display = 'flex';
        } else {
            element2.style.display = 'none';
        }
    };

    function toggleVisibility4() {
        let element = document.querySelector('.section-4-tab-body-1');
        let element2 = document.querySelector('.tab-title-img-1');
        let element3 = document.querySelector('.tab-title-img-2');
        let element4 = document.querySelector('.section-4-tab-title-1');
        element4.style.borderBottomLeftRadius = "10px";
        element4.style.borderBottomRightRadius = "10px";
        element.style.display = 'none';
        element2.style.display = 'none';
        element3.style.display = 'flex';
    };
    function toggleVisibility5() {
        let element = document.querySelector('.section-4-tab-body-1');
        let element2 = document.querySelector('.tab-title-img-1');
        let element3 = document.querySelector('.tab-title-img-2');
        let element4 = document.querySelector('.section-4-tab-title-1');
        element4.style.borderBottomLeftRadius = "0px";
        element4.style.borderBottomRightRadius = "0px";
        element.style.display = 'flex';
        element2.style.display = 'flex';
        element3.style.display = 'none';
    };

    function toggleVisibility45() {
        let element = document.querySelector('.section-4-tab-body-1');
        let element2 = document.querySelector('.tab-title-img-1');
        let element3 = document.querySelector('.tab-title-img-2');
        let element4 = document.querySelector('.section-4-tab-title-1');

        if (isExpanded45) {
            // Закрыть
            element.style.display = 'none';
            element2.style.display = 'none';
            element3.style.display = 'flex';
            element4.style.borderBottomLeftRadius = "10px";
            element4.style.borderBottomRightRadius = "10px";
        } else {
            // Открыть
            element.style.display = 'flex';
            element2.style.display = 'flex';
            element3.style.display = 'none';
            element4.style.borderBottomLeftRadius = "0px";
            element4.style.borderBottomRightRadius = "0px";
        }

        // Переключаем состояние
        setIsExpanded45(!isExpanded45)
    }

    function toggleVisibility6() {
        let element = document.querySelector('.section-4-tab-body-2');
        let element2 = document.querySelector('.tab-title-img-11');
        let element3 = document.querySelector('.tab-title-img-12');
        let element4 = document.querySelector('.section-4-tab-title-2');
        element.style.display = 'flex';
        element2.style.display = 'flex';
        element3.style.display = 'none';
        element4.style.borderBottomLeftRadius = "0px";
        element4.style.borderBottomRightRadius = "0px";
    };
    function toggleVisibility7() {
        let element = document.querySelector('.section-4-tab-body-2');
        let element2 = document.querySelector('.tab-title-img-11');
        let element3 = document.querySelector('.tab-title-img-12');
        let element4 = document.querySelector('.section-4-tab-title-2');
        element.style.display = 'none';
        element2.style.display = 'none';
        element3.style.display = 'flex';
        element4.style.borderBottomLeftRadius = "10px";
        element4.style.borderBottomRightRadius = "10px";
    };

    function toggleVisibility67() {
        let element = document.querySelector('.section-4-tab-body-2');
        let element2 = document.querySelector('.tab-title-img-11');
        let element3 = document.querySelector('.tab-title-img-12');
        let element4 = document.querySelector('.section-4-tab-title-2');

        if (isExpanded67) {
            // Закрыть
            element.style.display = 'none';
            element2.style.display = 'none';
            element3.style.display = 'flex';
            element4.style.borderBottomLeftRadius = "10px";
            element4.style.borderBottomRightRadius = "10px";
        } else {
            // Открыть
            element.style.display = 'flex';
            element2.style.display = 'flex';
            element3.style.display = 'none';
            element4.style.borderBottomLeftRadius = "0px";
            element4.style.borderBottomRightRadius = "0px";
        }

        // Переключаем состояние
        setIsExpanded67(!isExpanded67)
    }

    function toggleVisibility8() {
        let element = document.querySelector('.section-4-tab-body-3');
        let element2 = document.querySelector('.tab-title-img-21');
        let element3 = document.querySelector('.tab-title-img-22');
        let element4 = document.querySelector('.section-4-tab-title-3');
        element.style.display = 'flex';
        element2.style.display = 'flex';
        element3.style.display = 'none';
        element4.style.borderBottomLeftRadius = "0px";
        element4.style.borderBottomRightRadius = "0px";
    };
    function toggleVisibility9() {
        let element = document.querySelector('.section-4-tab-body-3');
        let element2 = document.querySelector('.tab-title-img-21');
        let element3 = document.querySelector('.tab-title-img-22');
        let element4 = document.querySelector('.section-4-tab-title-3');
        element.style.display = 'none';
        element2.style.display = 'none';
        element3.style.display = 'flex';
        element4.style.borderBottomLeftRadius = "10px";
        element4.style.borderBottomRightRadius = "10px";
    };

    function toggleVisibility89() {
        let element = document.querySelector('.section-4-tab-body-3');
        let element2 = document.querySelector('.tab-title-img-21');
        let element3 = document.querySelector('.tab-title-img-22');
        let element4 = document.querySelector('.section-4-tab-title-3');

        if (isExpanded89) {
            // Закрыть
            element.style.display = 'none';
            element2.style.display = 'none';
            element3.style.display = 'flex';
            element4.style.borderBottomLeftRadius = "10px";
            element4.style.borderBottomRightRadius = "10px";
        } else {
            // Открыть
            element.style.display = 'flex';
            element2.style.display = 'flex';
            element3.style.display = 'none';
            element4.style.borderBottomLeftRadius = "0px";
            element4.style.borderBottomRightRadius = "0px";
        }

        // Переключаем состояние
        setIsExpanded89(!isExpanded89)
    }

    function toggleVisibility20() {
        let element = document.querySelector('.section-7-tab-body-0');
        let element2 = document.querySelector('.tab-title-img-002');
        let element3 = document.querySelector('.tab-title-img-0011');
        let element4 = document.querySelector('.section-7-tab-title-0');
        element.style.display = 'none';
        element2.style.display = 'flex';
        element3.style.display = 'none';
        element4.style.borderBottomLeftRadius = "10px";
        element4.style.borderBottomRightRadius = "10px";
    };
    function toggleVisibility21() {
        let element = document.querySelector('.section-7-tab-body-0');
        let element2 = document.querySelector('.tab-title-img-002');
        let element3 = document.querySelector('.tab-title-img-0011');
        let element4 = document.querySelector('.section-7-tab-title-0');
        element.style.display = 'flex';
        element2.style.display = 'none';
        element3.style.display = 'flex';
        element4.style.borderBottomLeftRadius = "0px";
        element4.style.borderBottomRightRadius = "0px";
    };

    function toggleVisibility10() {
        let element = document.querySelector('.section-7-tab-body-1');
        let element2 = document.querySelector('.tab-title-img-102');
        let element3 = document.querySelector('.tab-title-img-101');
        let element4 = document.querySelector('.section-7-tab-title-1');
        element.style.display = 'none';
        element2.style.display = 'flex';
        element3.style.display = 'none';
        element4.style.borderBottomLeftRadius = "10px";
        element4.style.borderBottomRightRadius = "10px";
    };
    function toggleVisibility11() {
        let element = document.querySelector('.section-7-tab-body-1');
        let element2 = document.querySelector('.tab-title-img-102');
        let element3 = document.querySelector('.tab-title-img-101');
        let element4 = document.querySelector('.section-7-tab-title-1');
        element.style.display = 'flex';
        element2.style.display = 'none';
        element3.style.display = 'flex';
        element4.style.borderBottomLeftRadius = "0px";
        element4.style.borderBottomRightRadius = "0px";
    };
    function toggleVisibility12() {
        let element = document.querySelector('.section-7-tab-body-2');
        let element2 = document.querySelector('.tab-title-img-202');
        let element3 = document.querySelector('.tab-title-img-201');
        let element4 = document.querySelector('.section-7-tab-title-2');
        element.style.display = 'flex';
        element2.style.display = 'none';
        element3.style.display = 'flex';
        element4.style.borderBottomLeftRadius = "0px";
        element4.style.borderBottomRightRadius = "0px";
    };
    function toggleVisibility13() {
        let element = document.querySelector('.section-7-tab-body-2');
        let element2 = document.querySelector('.tab-title-img-202');
        let element3 = document.querySelector('.tab-title-img-201');
        let element4 = document.querySelector('.section-7-tab-title-2');
        element.style.display = 'none';
        element2.style.display = 'flex';
        element3.style.display = 'none';
        element4.style.borderBottomLeftRadius = "10px";
        element4.style.borderBottomRightRadius = "10px";
    };
    function toggleVisibility14() {
        let element = document.querySelector('.section-7-tab-body-3');
        let element2 = document.querySelector('.tab-title-img-302');
        let element3 = document.querySelector('.tab-title-img-301');
        let element4 = document.querySelector('.section-7-tab-title-3');
        element.style.display = 'flex';
        element2.style.display = 'none';
        element3.style.display = 'flex';
        element4.style.borderBottomLeftRadius = "0px";
        element4.style.borderBottomRightRadius = "0px";
    };
    function toggleVisibility15() {
        let element = document.querySelector('.section-7-tab-body-3');
        let element2 = document.querySelector('.tab-title-img-302');
        let element3 = document.querySelector('.tab-title-img-301');
        let element4 = document.querySelector('.section-7-tab-title-3');
        element.style.display = 'none';
        element2.style.display = 'flex';
        element3.style.display = 'none';
        element4.style.borderBottomLeftRadius = "10px";
        element4.style.borderBottomRightRadius = "10px";
    };
    function toggleVisibility16() {
        let element = document.querySelector('.section-7-tab-body-4');
        let element2 = document.querySelector('.tab-title-img-402');
        let element3 = document.querySelector('.tab-title-img-401');
        let element4 = document.querySelector('.section-7-tab-title-4');
        element.style.display = 'flex';
        element2.style.display = 'none';
        element3.style.display = 'flex';
        element4.style.borderBottomLeftRadius = "0px";
        element4.style.borderBottomRightRadius = "0px";
    };
    function toggleVisibility17() {
        let element = document.querySelector('.section-7-tab-body-4');
        let element2 = document.querySelector('.tab-title-img-402');
        let element3 = document.querySelector('.tab-title-img-401');
        let element4 = document.querySelector('.section-7-tab-title-4');
        element.style.display = 'none';
        element2.style.display = 'flex';
        element3.style.display = 'none';
        element4.style.borderBottomLeftRadius = "10px";
        element4.style.borderBottomRightRadius = "10px";
    };

    const handleClickAskQuestion = () => setShowFormContactUs(!showFormContactUs)



    const handleClickPrivPolicy = () => {
        // setIsModalOpen(true);
        // navigate('/privacy')
        window.open('/privacy', '_blank');
    };

    const handleClickTerms = () => {
        // setIsModalOpen(true);
        // navigate('/privacy')
        window.open('/terms', '_blank');
    };


    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleOpenChat = () => {
        console.log('handleOpenChat')
        if (window.jivo_api) {
            window.jivo_api.open();
        } else {
            console.error('JivoChat API is not loaded yet.');
        }
    };

    const text1 = business ? 'Market leadership through ' : 'Join the '
    const text2 = business ? 'Carbon Neutrality' : 'Carbon Revolution'
    const text3 = business ? 'Successful companies realise that action on climate is critical to their success.' : `Learn to Combat Climate Change and Make a Positive Impact with Carbon
                                    Learn.`
    const text4 = business ? `How will business benefit ?` : `How will I benefit ?`
    const text5 = business ? `– Show commitment to climate change measures to your stakeholders and shareholders and improving your image ` : `– Initiate the commitment to delivering your organization’s carbon management plan`
    const text6 = business ? '– Differentiate yourself from competitors as climate friendly ' : '– Understand what’s required to monitor your plan'

    const text7 = business ? '– Save cost by closely monitoring your company’s emissions and identifying areas of improvement ' : '– Receive an training certificate of attendance'
    const text8 = business ? '– Receive certification that validates your commitment to carbon neutrality' : '– Develop the foundational knowledge to support your progress to carbon neutrality'
    const text9 = business ? '' : '– Gain a professional competitive advantage'

    const text10 = business ? 'Carbon Learn is an innovative and comprehensive course designed specifically for businesses committed to reducing their carbon footprint and leading the charge in climate action. This course equips your team with the critical knowledge and skills needed to navigate the complexities of carbon management.' : `Carbon Learn is an innovative and comprehensive course for sustainability
                            professionals and enthusiasts that equips you with the knowledge and skills to achieve carbon
                            neutrality and participate in carbon markets.`

    const text11 = business ? `Our course offers a unique opportunity to learn from experts in the field, explore the latest strategies and technologies for reducing emissions, and understand the complexities of carbon markets.` : `Our course offers practical knowledge and skills that are highly valued in today's job market and can help you gain a professional competitive advantage while making a positive impact on the environment.`

    const text12 = business ? `Our course offers a unique opportunity to gain knowledge from experts in the field, explore the latest strategies and technologies to achieve carbon neutrality and participate in carbon markets. Enroll today and take the first step towards achieving carbon neutrality and a sustainable future.` :
        `Our course offers practical knowledge and skills that are
                                highly valued in today's job market and can help you advance your career while making a positive
                                impact on the environment. Enroll today and take the first step towards achieving carbon
                                neutrality and a sustainable future.`
    const text13 = business ? `This course will help your team understand, develop, implement and monitor a robust carbon management plan that meets the needs of your organisation.
There are no eligibility requirements.`: `This course is ideal for individuals who work in
                                            sustainability, environmental or energy management and also for those who want to
                                            understand what the road to carbon neutrality.
                                            There are no eligibility requirements.`
    return (
        <>
            {/* <PrivacyPolicyModal isOpen={isModalOpen} onClose={handleCloseModal} /> */}

            <header className="header">
                <div className="container">
                    <div className="header-contant">
                        <div className="header-logo" style={{ display: 'flex', gap: '19px' }}>
                            <div style={{ cursor: 'pointer' }} className="logo-1" onClick={() => navigate('/')}><img src={logo1} alt="logo" /></div>
                            <div style={{ cursor: 'pointer' }} className="logo-2" onClick={() => navigate('/')}><img src={logo2} alt="logo" /></div>
                            {/* <div className='header_logo_text'>Carbon learn</div> */}
                        </div>
                        <div className="header-nav">
                            <ul className="main-menu desktop-flex" data-da=".mobile-conteiner,767,0">
                                <li><a href="#about">About us</a></li>
                                <li><a href="#course">Course</a></li>
                                <li><a href="#price">Price</a></li>
                                <li><a href="#FAQ">FAQ</a></li>
                                <li><a href="#contact">Contact</a></li>
                            </ul>
                        </div>
                        <div className="header-button">
                            <div className="button button-header desktop" onClick={handleClickLogIn} data-da=".mobile-conteiner,767,1">Log in</div>
                        </div>
                        <div className="blok-menu-mobile">
                            <img onClick={toggleVisibility} className="burger-1" id="burger-1" src={burger1} alt="burger" />
                            <img onClick={toggleVisibility2} className="burger-2" id="burger-2" src={burger2} alt="burger" />
                        </div>
                    </div>
                </div>
                <div className="mobile-conteiner" id="mobile-conteiner">
                    <ul onClick={() => toggleVisibility()} className="main-menu _dynamic_adapt_" data-da=".mobile-conteiner,767,0">
                        <li ><a onclick="toggleVisibility3()" href="#about">About us</a></li>
                        <li><a onclick="toggleVisibility3()" href="#course">Course</a></li>
                        <li><a onclick="toggleVisibility3()" href="#price">Price</a></li>
                        <li><a onclick="toggleVisibility3()" href="#FAQ">FAQ</a></li>
                        <li><a onclick="toggleVisibility3()" href="#contact">Contact</a></li>
                    </ul>
                    <div className="button button-header _dynamic_adapt_" onClick={handleClickLogIn}>Log in</div>
                </div>
            </header>

            <section className="section-1">
                <div className="container">
                    <div className="section-1-contant">
                        <div className="section-1-blok-text">
                            <h1 style={{ width: '50vw' }} className="h1">{text1}<span>{text2}</span></h1>
                            <p className="section-1-text">Enrol in our comprehensive educational course on Carbon Neutrality and
                                Carbon
                                Markets and
                                become a leader in the global fight against climate change.</p>
                            <img className="s1-image-2" src={s1Image1Mob} alt="" />
                            <div className="section-1-blok-text-2" data-da=".section-1-blok-text,667,3">
                                <p className="section-1-text-2">{text3}</p>
                            </div>
                            <a className="button button-1" href="#price">Enrol now</a>
                        </div>

                        <img className="s1-image" src={s1Image1} alt="" />
                    </div>
                </div>
            </section>

            <section className="section-2" id="about">
                <div className="container">
                    <div className="section-2-contant">
                        <h2 className="h2">{text4}</h2>
                        <div className="section-2-items">
                            <div className="section-2-item section-2-item-1">
                                <p className="section-2-text">
                                    <img className="s2-image-4" src={x_img1} alt="benefit" />
                                    {text5}<br /><br />
                                    {text6}<br /><br />
                                    {text7}<br /><br />
                                    {text8}<br /><br />
                                    {text9}
                                    <img className="s2-image-5" src={x_img2} alt="benefit" />
                                </p>
                            </div>
                            <div className="section-2-item section-2-item-2">
                                <img className="s2-image s2-image-1" src={s2Image1} alt="benefit" />
                                <img className="s2-image s2-image-2" src={s2Image2} alt="benefit" />
                                <img className="s2-image s2-image-3" src={s2Image3} alt="benefit" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-3" id="course">
                <div className="container">
                    <div className="section-3-contant">
                        <span className="h2-big">Carbon Learn course</span>
                        <h2 className="h2 h2-s3">Carbon Learn course</h2>
                        <p className="section-3-text">In today's world, climate change is one of the most pressing global
                            challenges, and businesses, governments, and individuals all have a role to play in reducing
                            greenhouse gas emissions.</p>
                        <div className="section-3-video">
                            {/* <video className="video-1" id="myvid" controls="controls" poster="video/s3-image-1.jpg">
                                <source src="http://sitedlyavseh.ru/video/video-1.mp4"
                                    type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
                            </video> */}
                            {/* <iframe style={{ borderRadius: '7px' }} width="928" height="350" src="https://www.youtube.com/embed/HK8LLWSIIm4" title="Climate Science in a Nutshell #4: Too Much Carbon Dioxide" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
                            {/* <button type="button" id="play_button">
                                <img src={s3img2} alt="video" />
                            </button> */}
                            <iframe style={{ borderRadius: '7px' }} width="623" height="350" src="https://www.youtube.com/embed/H1qepn6A9BM" title="Carbon Learn Promo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <p className="section-3-text">{text10}</p>
                    </div>

                </div>
            </section>

            <section className="section-3 section-4">
                <div className="container">
                    <div className="section-3-contant">
                        <span className="h2-big">Program Course</span>
                        <h2 className="h2 h2-s3">Program Course</h2>
                        <p className="section-3-text">Our course offers a unique opportunity to learn from experts in the field,
                            explore the latest strategies and technologies for reducing emissions, and understand the
                            complexities of carbon markets and trading. With Carbon Learn, you'll gain a competitive advantage
                            in a world that is rapidly transitioning to a low-carbon economy.</p>

                        <div className="section-4-tab section-4-tab-1">
                            <div style={{ cursor: 'pointer' }} onClick={toggleVisibility45} className="section-4-tab-title section-4-tab-title-1" id="section-4-tab-title-1">
                                <p className="">Module 1: Introduction to Carbon Neutrality</p>
                                <img className="tab-title-img-1" id="tab-title-img-1" onClick={toggleVisibility4} src={s4Icon1} alt="icon" />
                                <img className="tab-title-img-2" id="tab-title-img-2" onClick={toggleVisibility5} src={s4Icon2} alt="icon" />
                            </div>
                            <div className="section-4-tab-body section-4-tab-body-1" id="section-4-tab-body-1">
                                <div className="section-4-tab-body-1-col-1">
                                    <p className="section-4-tab-body-text">In this module, we will introduce you to the concept of
                                        carbon neutrality and its
                                        significance in mitigating the impact of climate change and its impact on the
                                        environment,
                                        society, and economy.</p>
                                    <p className="section-4-tab-body-text"><span>Lesson 1:</span> Introduction to carbon neutrality
                                        and
                                        climate
                                        change</p>
                                    <p className="section-4-tab-body-text"><span>Lesson 2:</span> Understanding Carbon Neutrality
                                    </p>
                                    <p className="section-4-tab-body-text"><span>Lesson 3:</span> The Importance of Carbon
                                        Neutrality
                                    </p>
                                    <p className="section-4-tab-body-text"><span>Lesson 4:</span> Implementing Carbon Neutrality in
                                        Organizations</p>
                                </div>
                                <div className="section-4-tab-body-1-col-2">
                                    <div className="section-4-video">
                                        {/* <video className="video-2" id="myvid-2" controls="controls" poster="video/s3-image-1.jpg">
                                            <source src="http://sitedlyavseh.ru/video/video-2.mp4"
                                                type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'></source>
                                        </video> */}
                                        <div style={{ borderRadius: '7px', overflow: 'hidden', height: '222px' }}>
                                            {/* <iframe width="428" height="222" src="https://www.youtube.com/embed/-7SNlRUopSQ" title="Why Carbon Credits Are The Next Opportunity For Farmers" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
                                            <iframe width="396" height="222" src="https://www.youtube.com/embed/qQ2_Auj21Cc" title="Module 1: Introduction to Carbon Neutrality_Carbon Learn" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </div>
                                        {/* <button type="button" id="play_button-2">
                                            <img src={s3Image2} alt="video" />
                                        </button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="section-4-tab section-4-tab-2">
                            <div style={{ cursor: 'pointer' }} onClick={toggleVisibility67} class="section-4-tab-title section-4-tab-title-2">
                                <p class="">Module 2: Carbon Footprint and Emissions Reduction</p>
                                <img class="tab-title-img-11" onClick={toggleVisibility7} src={s4Icon1} alt="icon" />
                                <img class="tab-title-img-12" onClick={toggleVisibility6} src={s4Icon2} alt="icon" />
                            </div>
                            <div class="section-4-tab-body section-4-tab-body-2">
                                <div class="section-4-tab-body-1-col-1 section-4-tab-body-1-col-1-2">
                                    <div class="section-4-video">
                                        {/* <video class="video-3" id="myvid-3" controls="controls" poster="video/s3-image-1.jpg">
                                                    <source src="http://sitedlyavseh.ru/video/video-2.mp4"
                                                        type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
                                                </video>
                                                <button type="button" id="play_button-3">
                                                    <img src="img/s3-image-2.png" alt="video"/>
                                                </button> */}
                                        {/* <iframe style={{ borderRadius: '7px' }} width="428" height="222" src="https://www.youtube.com/embed/-7SNlRUopSQ" title="Why Carbon Credits Are The Next Opportunity For Farmers" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
                                        <iframe style={{ borderRadius: '7px' }} width="396" height="222" src="https://www.youtube.com/embed/QWjCpCZyCks" title="Module 2: Carbon Footprint and Emissions Reduction_Carbon Learn" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                    </div>
                                </div>
                                <div class="section-4-tab-body-1-col-2 section-4-tab-body-1-col-2-2">

                                    <p class="section-4-tab-body-text">In this module, we will focus on understanding the
                                        concept of carbon footprint and explore ways to reduce greenhouse gas emissions. We
                                        will
                                        cover a range of topics, including measuring carbon footprint, identifying emission
                                        sources, and developing emissions reduction strategies.</p>
                                    <p class="section-4-tab-body-text"><span>Lesson 1:</span> Understanding Carbon Footprint
                                    </p>
                                    <p class="section-4-tab-body-text"><span>Lesson 2:</span> Methods of Measuring Carbon
                                        Footprint</p>
                                    <p class="section-4-tab-body-text"><span>Lesson 3:</span> Strategies for Emissions
                                        Reduction
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div class="section-4-tab section-4-tab-3">
                            <div style={{ cursor: 'pointer' }} onClick={toggleVisibility89} class="section-4-tab-title section-4-tab-title-3">
                                <p class="">Module 3: Carbon Markets and Offsets</p>
                                <img class="tab-title-img-21" onClick={toggleVisibility9} src={s4Icon1} alt="icon" />
                                <img class="tab-title-img-22" onClick={toggleVisibility8} src={s4Icon2} alt="icon" />
                            </div>
                            <div class="section-4-tab-body section-4-tab-body-3">
                                <div class="section-4-tab-body-1-col-1">
                                    <p class="section-4-tab-body-text"> In this module, we will explore the concept of carbon markets and how they can play a role in reducing greenhouse gas emissions. We will also discuss the use of carbon offsets as a strategy to mitigate emissions, and the challenges and controversies associated with this approach.</p>
                                    <p class="section-4-tab-body-text"><span>Lesson 1: </span>What are Carbon Markets?</p>
                                    <p class="section-4-tab-body-text"><span>Lesson 2: </span>What is carbon credit?</p>
                                    <p class="section-4-tab-body-text"><span>Lesson 3: </span>VCM: main trends and challenges</p>
                                    <p class="section-4-tab-body-text"><span>Lesson 4: </span>Emissions Trading Systems (ETSs)</p>
                                </div>
                                <div class="section-4-tab-body-1-col-2">
                                    <div class="section-4-video">
                                        {/* <video class="video-4" id="myvid-4" controls="controls" poster="video/s3-image-1.jpg">
                                                        <source src="http://sitedlyavseh.ru/video/video-2.mp4"
                                                            type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
                                                    </video>
                                                    <button type="button" id="play_button-4">
                                                        <img src="img/s3-image-2.png" alt="video"/>
                                                    </button> */}
                                        {/* <iframe style={{ borderRadius: '7px' }} width="428" height="222" src="https://www.youtube.com/embed/-7SNlRUopSQ" title="Why Carbon Credits Are The Next Opportunity For Farmers" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
                                        <iframe style={{ borderRadius: '7px' }} width="396" height="222" src="https://www.youtube.com/embed/FX_mZIjrOvE" title="Module 3: Carbon Markets and Offsets_Carbon Learn" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="section-4-blok-button">
                            <p class="section-4-blok-button-text"> + access additional resources, including case studies,
                                research papers and practical
                                tools provided by experts from CarbonTracker</p>
                            <a href="#price" class="button button-1 button-2">Get Started</a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-3 section-5">
                <div className="container">
                    <div className="section-5-contant">
                        <span className="h2-big">Our partners</span>
                        <h2 className="h2 h2-s3 h2-s5">Our partners</h2>
                        <div className="section-5-items">
                            <div className="section-5-item section-5-item-1">
                                <img className="section-5-image section-5-image-1" src={s5Image1} alt="partners" />
                                <p className="section-5-text">This course was developed with the support of experts from
                                    CarbonTracker – a cloud-based solution for accounting and automatic calculation of
                                    greenhouse gas emissions.</p>
                            </div>
                            <div className="section-5-item section-5-item-2">
                                <img className="section-5-image section-5-image-2" src={s5Image2} alt="partners" />
                                <p className="section-5-text">We are a member of world-class business and technology community
                                    «Masdar City» – a thriving ecosystem of leading global organizations that are working
                                    together to create a greener tomorrow.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-6" id="price">
                <div className="container">
                    <div className="section-6-contant">
                        <div className="section-6-item section-6-item-1">
                            <div className="section-6-text-1">Join the Carbon Revolution today!</div>
                            <div className="section-6-text-2">Receive lifetime access to the knowledge and skills to achieve carbon
                                neutrality and participate in carbon markets.</div>
                            <div className="button button-1 button-2" onClick={() => navigate('/confirm')}>Get Started</div>
                        </div>
                        <div className="section-6-item section-6-item-2">
                            <div className="section-6-text-3" data-da=".section-6-item-1,500,2">150$</div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-3 section-7" id="FAQ">
                <div className="container">
                    <div className="section-3-contant section-7-contant">
                        <span className="h2-big">FAQ</span>
                        <h2 className="h2 h2-s3 h2-s7">FAQ</h2>
                        <p className="section-3-text">(Answers to your most frequently asked questions about Carbon Learn course)
                        </p>
                        <div className="section-7-items">
                            <div className="section-7-item section-7-item-1">
                                <div className="section-4-tab section-7-tab-1">
                                    <div onClick={toggleVisibilityNew0} className="section-4-tab-title section-7-tab-title section-7-tab-title-0">
                                        <p className="">How much does the course cost?</p>
                                        <img className="tab-title-img-0011" onClick={toggleVisibility20} src={s7Icon1} alt="icon" />
                                        <img className="tab-title-img-002" onClick={toggleVisibility21} src={s7Icon2} alt="icon" />
                                    </div>
                                    <div className="section-4-tab-body section-7-tab-body-0">
                                        <p className="section-4-tab-body-text">The cost of the course is 150$. We accept different types of online payment methods.</p>
                                    </div>
                                </div>
                                <div className="section-4-tab section-7-tab-1">
                                    <div onClick={toggleVisibilityNew} className="section-4-tab-title section-7-tab-title section-7-tab-title-1">
                                        <p className="">Who is it for?</p>
                                        <img className="tab-title-img-101" onClick={toggleVisibility10} src={s7Icon1} alt="icon" />
                                        <img className="tab-title-img-102" onClick={toggleVisibility11} src={s7Icon2} alt="icon" />
                                    </div>
                                    <div className="section-4-tab-body section-7-tab-body-1">
                                        <p className="section-4-tab-body-text">{text13}</p>
                                    </div>
                                </div>
                                <div className="section-4-tab section-7-tab-2">
                                    <div onClick={toggleVisibilityNew1} className="section-4-tab-title section-7-tab-title section-7-tab-title-2">
                                        <p className="">What is the course of study like?</p>
                                        <img className="tab-title-img-201" onClick={toggleVisibility13} src={s7Icon1} alt="icon" />
                                        <img className="tab-title-img-202" onClick={toggleVisibility12} src={s7Icon2} alt="icon" />
                                    </div>
                                    <div className="section-4-tab-body section-7-tab-body-2">
                                        <p className="section-4-tab-body-text">Participants can access the course through our online
                                            learning platform, where they can complete lessons at their own pace. They can also
                                            access additional resources, including case studies, research papers and practical
                                            tools that can help them apply the knowledge and skills they have learned in their
                                            organizations or daily lives.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="section-7-item section-7-item-2">
                                <div className="section-4-tab section-7-tab-3">
                                    <div onClick={toggleVisibilityNew2} className="section-4-tab-title section-7-tab-title section-7-tab-title-3">
                                        <p className="">How long with the course take me to complete?</p>
                                        <img className="tab-title-img-301" onClick={toggleVisibility15} src={s7Icon1} alt="icon" />
                                        <img className="tab-title-img-302" onClick={toggleVisibility14} src={s7Icon2} alt="icon" />
                                    </div>
                                    <div className="section-4-tab-body section-7-tab-body-3">
                                        <p className="section-4-tab-body-text">We expect each module to take between 60 and 100
                                            minutes, about 3.5 hours in total.</p>
                                    </div>
                                </div>
                                <div className="section-4-tab section-7-tab-4">
                                    <div onClick={toggleVisibilityNew3} className="section-4-tab-title section-7-tab-title section-7-tab-title-4">
                                        <p className="">Why do I have to take this course?</p>
                                        <img className="tab-title-img-401" onClick={toggleVisibility17} src={s7Icon1} alt="icon" />
                                        <img className="tab-title-img-402" onClick={toggleVisibility16} src={s7Icon2} alt="icon" />
                                    </div>
                                    <div className="section-4-tab-body section-7-tab-body-4">
                                        <p className="section-4-tab-body-text">{text11}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            // onClick={handleClickAskQuestion} 
                            onClick={handleOpenChat}
                            className="button button-1 button-7 open-popup"
                        >
                            Ask a question
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-8">
                <div className="container">
                    <div className="section-8-contant">
                        <p className="section-8-text">Join the Carbon Revolution today with Carbon Learn and take
                            the first step towards a sustainable future.</p>
                        <a className="button button-1 button-8" href="#price">Enrol now</a>
                    </div>
                </div>
            </section>

            <footer className="footer" id="contact">
                <div className="container">
                    <div className="footer-contant">
                        <div className="footer-logo">
                            <a className="logo-3" href="index.html"><img src={footerLogo} alt="logo" /></a>
                            <div className="footer-text footer-text-1">{text12}

                                <br /><br />© 2024 Learn Carbon Academy</div>

                        </div>
                        <div className="footer-col2"></div>
                        <div className="footer-nav">
                            <p className="footer-text-2">Menu</p>
                            <ul className="footer-menu">
                                <li><a href="#about">About</a></li>
                                <li><a href="#course">Course</a></li>
                                <li><a href="#price">Price</a></li>
                                <li><a href="#FAQ">FAQ</a></li>
                                <li><a style={{ cursor: 'pointer' }} onClick={handleClickLogIn} >Personal account</a></li>
                            </ul>
                        </div>
                        <div className="footer-contact">
                            <p className="footer-text-2">Contact</p>
                            {/* <a className="footer-link" href="tel:+35725060179">+3 (572) 506-01-79</a> */}
                            <a className="footer-link" href="mailto:info@learn-carbon.academy">info@learn-carbon.academy</a>
                            <div className="footer-text-1">UAE, Abu Dhabi, Masdar City Free Zone Resident</div>
                        </div>
                    </div>
                    <div className="footer-contant-down">
                        <a style={{ cursor: 'pointer' }} className="footer-link" onClick={handleClickPrivPolicy}>Privacy Policy</a>
                        <a style={{ cursor: 'pointer' }} className="footer-link" onClick={handleClickTerms}>Terms of Use</a>
                    </div>
                </div>
            </footer>
            {showFormContactUs && <ContactUs handleClickAskQuestion={handleClickAskQuestion} />}
        </>
    );
}

export default LandingPage;
